(function (window, document, $) {
  $(document).ready(function () {
    const modal = $('.mcl-modal');
    const $html = $("html");
    const $body = $("body");

    if (modal.length > 0) {
      $body.on($.modal.OPEN, '.modal', function (event) {
        $html.css('overflow', 'hidden');
        modal.closest('.jquery-modal').addClass('overlay');
      });
      $body.on($.modal.CLOSE, '.modal', function (event) {
        $html.css('overflow', '');
        modal.closest('.jquery-modal').removeClass('overlay');
      });
      modal.find('.mcl-atom-link').on('click touch', function (e) {
        e.preventDefault();
        $.modal.close();
      });

      // trigger all modal
      $body.on('click touch', '[href^="#"][href$="-modal"]', function (e) {
        e.preventDefault();
        $.modal.close();
        var target = $(this).attr('href');
        const $confirmDialog = $(target);
        $confirmDialog.modal({
          fadeDuration: 100,
          fadeDelay: 0.50,
          closeExisting: true
        });
      })
    }
  })
})(window, document, jQuery);
