(function (window, document, $) {
    $(document).ready(function () {
      $accordion = $('.tim-accordion-read');
      if ($accordion.length > 0) {
        // Prevent opening first accordion on page load
        //$accordion.find("div:first").css("display", "block");
        //$accordion.find("h3:first svg").addClass('rotate');
        $('body').on('click touch', '.tim-accordion-read h3', function () {
          $(this).next().slideToggle(300);
          //$(this).closest('.tim-accordion-read').find("div").not($(this).next()).slideUp(300);
          //$accordion.find("h3 svg").removeClass('rotate');
          $(this).find("svg").toggleClass('rotate');
          $(this).closest('.tim-accordion-read').find('h3 svg').not($(this).find('svg')).removeClass('rotate');
        });
      }
    })
  })(window, document, jQuery);
  
