(function (window, document, $, ajax_url) {
/*
  $(window).keydown(function(event){
    if(event.keyCode === 13) {
      event.preventDefault();
      return false;
    }
  });
*/
	window.scrollToElement = function(element, extra_offset) {

		$scrollMargin = $('.tim-header-menu').height();

    $('html').animate(
			{
				scrollTop: ($(element).offset().top - $scrollMargin - (extra_offset ?? 0)),
			},
			800 //speed
		);
	}

  const delay = 500
  const minZoomTouchDelta = 100

  let lastTapAt = 0
  let lastClientX = 0
  let lastClientY = 0

  window.preventDoubleTapZoom = function (event) {
    if (!event.touches || event.touches.length > 1) {
      return
    }

    const tapAt = new Date().getTime()
    const timeDiff = tapAt - lastTapAt
    const { clientX, clientY } = event.touches[0]
    const xDiff = Math.abs(lastClientX - clientX)
    const yDiff = Math.abs(lastClientY - clientY)
    if (
      xDiff < minZoomTouchDelta &&
      yDiff < minZoomTouchDelta &&
      event.touches.length === 1 &&
      timeDiff < delay
    ) {
      event.preventDefault()
      event.target.click()
    }
    lastClientX = clientX
    lastClientY = clientY
    lastTapAt = tapAt
  };

  window.updateQueryParam = function(urlString, paramsToUpdate) {
    let url = new URL(urlString);
    let params = new URLSearchParams(url.search);

    for (const [key, value] of Object.entries(paramsToUpdate)) {
      if (value === null) {
        params.delete(key);
      } else {
        params.set(key, value);
      }
    }

    url.search = params.toString();
    return url.toString();
  }

})(window, document, jQuery, ajax_url);
